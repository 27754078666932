import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--trix"
export default class extends Controller {

    connect() {
    }

    disconnect() {
        super.disconnect();
    }
}

const textColors = {
    red: [220, 38, 38],
    rose: [225, 29, 72],
    pink: [219, 39, 119],
    fuchsia: [192, 38, 211],
    purple: [147, 51, 234],
    violet: [124, 58, 237],
    indigo: [79, 70, 229],
    blue: [37, 99, 235],
    sky: [2, 132, 199],
    cyan: [8, 145, 178],
    teal: [13, 148, 136],
    emerald: [5, 150, 105],
    green: [22, 163, 74],
    lime: [101, 163, 13],
    yellow: [202, 138, 4],
    amber: [217, 119, 6],
    orange: [234, 88, 12],
    gray: [75, 85, 99],
    zinc: [82, 82, 91],
    slate: [71, 85, 105],
    stone: [87, 83, 78],
    neutral: [82, 82, 82]
};

const backgroundColors = {
    "red": [252, 165, 165],
    "rose": [253, 164, 175],
    "pink": [249, 168, 212],
    "fuchsia": [240, 171, 252],
    "purple": [216, 180, 254],
    "violet": [196, 181, 253],
    "indigo": [165, 180, 252],
    "blue": [147, 197, 253],
    "sky": [125, 211, 252],
    "cyan": [103, 232, 249],
    "teal": [94, 234, 212],
    "emerald": [110, 231, 183],
    "green": [134, 239, 172],
    "lime": [190, 242, 100],
    "yellow": [253, 224, 71],
    "amber": [252, 211, 77],
    "orange": [253, 186, 116],
    "stone": [214, 211, 209],
    "neutral": [212, 212, 212],
    "zinc": [212, 212, 216],
    "gray": [209, 213, 219],
    "slate": [203, 213, 225]
};

window.addEventListener("trix-initialize", (event) => {
    Object.entries(textColors).forEach(([color, rgb]) => {
        Trix.config.textAttributes[`color-${color}`] = {
            style: {color: `rgb(${rgb.join(', ')})`},
            inheritable: true,
            parser: function (element) {
                var style = window.getComputedStyle(element);
                return style.color === `rgb(${rgb.join(', ')})`;
            },
        };
    })

    Object.entries(backgroundColors).forEach(([color, rgb]) => {
        Trix.config.textAttributes[`marker-${color}`] = {
            style: {backgroundColor: `rgb(${rgb.join(', ')})`},
            inheritable: true,
            parser: function (element) {
                var style = window.getComputedStyle(element);
                console.log(style.backgroundColor, `rgb(${rgb.join(', ')})`)
                return style.backgroundColor === `rgb(${rgb.join(', ')})`;
            },
        };
    })

    document.querySelector(".trix-button-group--text-tools").insertAdjacentHTML("beforeend", '<button type="button" class="trix-button trix-button--icon trix-button--icon-color" data-trix-action="x-color" title="Color" tabindex="-1"></button>')
    document.querySelector(".trix-button-group--text-tools").insertAdjacentHTML("beforeend", '<button type="button" class="trix-button trix-button--icon trix-button--icon-marker" data-trix-action="x-marker" title="Marker" tabindex="-1"></button>')
    document.querySelector(".trix-dialogs").insertAdjacentHTML('beforeend', `
<div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color">
  <div class="trix-dialog__color-fields">
    <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
    <div class="trix-button-group">${
        Object.entries(textColors).map(([color, rgb]) => {
            return `<button type="button" class="trix-button trix-button--dialog" data-trix-attribute="color-${color}" data-trix-method="hideDialog" style="color: rgb(${rgb.join(', ')})"></button>`
        }).join('')
    }</div>
  </div>
</div>`)

    document.querySelector(".trix-dialogs").insertAdjacentHTML('beforeend', `
<div class="trix-dialog trix-dialog--color" data-trix-dialog="x-marker" data-trix-dialog-attribute="x-marker">
  <div class="trix-dialog__color-fields">
    <input type="text" name="x-marker" class="trix-dialog-hidden__input" data-trix-input>
    <div class="trix-button-group">${
        Object.entries(backgroundColors).map(([color, rgb]) => {
            return `<button type="button" class="trix-button trix-button--dialog" data-trix-attribute="marker-${color}" data-trix-method="hideDialog" style="background-color: rgb(${rgb.join(', ')})"></button>`
        }).join('')
    }</div>
  </div>
</div>`)
}, true);

addEventListener("trix-file-accept", function (event) {
    event.preventDefault();
}, true);
