import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--csv-field"
export default class extends Controller {
    static targets = ['recordTemplate', 'table', 'scroller']

    connect() {
        this.tableTarget.querySelector('tbody').appendChild(document.importNode(this.recordTemplateTarget.content, true))
    }

    add(event) {
        event.preventDefault()
        this.tableTarget.querySelector('tbody').appendChild(document.importNode(this.recordTemplateTarget.content, true))
        this.scrollerTarget.scrollTop = this.scrollerTarget.scrollHeight
        this.tableTarget.querySelector('tbody>tr:last-child input').focus()
    }

    up(event) {
        let currentRecord = event.target.closest('tr')
        let previousRecord = currentRecord.previousElementSibling

        if (previousRecord) {
            this.tableTarget.querySelector('tbody').insertBefore(currentRecord, previousRecord)
        }
    }

    down(event) {
        let currentRecord = event.target.closest('tr')
        let nextRecord = currentRecord.nextElementSibling

        if (nextRecord) {
            this.tableTarget.querySelector('tbody').insertBefore(currentRecord, nextRecord.nextElementSibling)
        }
    }

    remove(event) {
        event.target.closest('tr').remove()
    }
}
