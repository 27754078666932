import {Controller} from "@hotwired/stimulus"
import Quill from "quill";
import {Delta} from "quill/core";

// Connects to data-controller="admin--quill"
export default class extends Controller {
    static targets = ["input", "container", "view", "source"]

    connect() {
        if (this.hasContainerTarget) {
            const toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                ['link', 'image', 'video', 'formula'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];

            const quill = new Quill(this.containerTarget, {
                modules: {
                    toolbar: toolbarOptions
                },
                bounds: this.element,
                theme: 'snow'
            });

            try {
                quill.setContents(new Delta(JSON.parse(this.inputTarget.value)))
            } catch (e) {
                console.error(e)
            }

            quill.on('text-change', (delta, oldDelta, source) => {
                this.inputTarget.value = JSON.stringify(quill.getContents().ops)
            });
        }

        if (this.hasViewTarget) {
            const options = {
                readOnly: true,
                modules: {
                    toolbar: null
                },
                theme: 'snow'
            };

            const quill = new Quill(this.viewTarget, options);

            try {
                quill.setContents(new Delta(JSON.parse(this.sourceTarget.value)))
            } catch (e) {
                console.error(e)
            }
        }
    }
}
